<template>
  <v-card class="elevation-12 mx-2">
    <v-toolbar
      color="primary"
      dark
      flat
      :dense="$vuetify.breakpoint.smAndDown"
    >
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-card-text @click="expand = !expand" class="display-3" v-bind:class="colour">
      <v-row>
        <v-col class="py-0">{{statistic}}<span v-if="units" class="subtitle-2 pl-0">{{units}}</span></v-col>
        <v-col class="py-0" v-if="min || max">
          <v-list dense class="py-0">
            <v-list-item style="min-height: 20px" v-if="min">
              <v-list-item-title class="green--text">{{ minText }}: {{min}}</v-list-item-title>
            </v-list-item>
            <v-list-item min-height="20px" v-if="max">
              <v-list-item-title class="red--text">{{ maxText }}: {{max}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-subtitle
      class="pt-0"
      v-if="subtitle"
    >{{subtitle}}</v-card-subtitle>
    <v-progress-linear
      v-if="progress"
      :value="progress"
      rounded
      :height="height"
      color="red"
      background-color="green"
    >
      <template v-slot="{ value }">
        <strong v-if="expand">{{ Math.min(100, Math.ceil(value)) }}% Offline</strong>
      </template>
    </v-progress-linear>
  </v-card>
</template>

<script>
export default {
  name: 'StatisticCard',
  props: {
    title: String,
    statistic: [String, Number],
    subtitle: String,
    units: String,
    colour: String,
    progress: Number,
    min: [String, Number],
    max: [String, Number],
    minText: {
      type: String,
      default: 'Min'
    },
    maxText: {
      type: String,
      default: 'Max'
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      expand: false
    }
  },
  computed: {
    height: function () {
      return this.expand ? 20 : 5
    }
  }
}
</script>

<style scoped>

</style>
