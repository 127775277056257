<template>
  <v-simple-table v-if="openingHours">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Day
        </th>
        <th class="text-left">
          Hours
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(hours, count) in openingHours"
        :key="`hours-${count}`"
        :class="hours.dayOfWeekText | highlightToday"
      >
        <td>{{ hours.dayOfWeekText }}</td>
        <td>{{ hours.openingHour }}:00 - {{hours.closingHour}}:00</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'StoreOpeningHours',
  data () {
    return {
    }
  },
  filters: {
    highlightToday: function (value) {
      return value === dayjs().format('ddd') ? 'font-weight-bold' : ''
    }
  },
  computed: {
    ...mapState('instore', ['storeDetails']),
    openingHours: function () {
      return this.storeDetails.openingHours
    }
  }
}
</script>

<style scoped>

</style>
