<template>
  <v-container>
    <page-title-block :store-name="storeName" page-name="Summary"></page-title-block>
    <v-row class="justify-lg-space-around" v-if="storeId">
      <v-col>
        <statistic-card
          :statistic="pickQueue"
          title="Pick Queue"
          :loading="loading"
        >
        </statistic-card>
      </v-col>
      <v-col>
        <statistic-card
          :statistic="picking"
          title="Picking"
          :loading="loading"
        >
        </statistic-card>
      </v-col>
      <v-col>
        <statistic-card
          :statistic="readyForCollection"
          title="Ready For Collection"
          :loading="loading"
        >
        </statistic-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="4">
        <v-card class="elevation-12 mx-2">
          <v-toolbar
            color="primary"
            dark
            flat
            :dense="$vuetify.breakpoint.smAndDown"
          >
            <v-toolbar-title>{{storeName}} Opening Hours</v-toolbar-title>
          </v-toolbar>
          <StoreOpeningHours></StoreOpeningHours>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import StatisticCard from '@/components/StatisticCard'
import StoreOpeningHours from '@/components/StoreOpeningHours'
import PageTitleBlock from '@/components/PageTitleBlock'

const axios = require('axios')
const axiosConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000
}
const statusMapping = require('../../../resources/statusMapping.json')

export default {
  name: 'AdminHome',
  components: { PageTitleBlock, StoreOpeningHours, StatisticCard },
  data () {
    return {
      axios: axios.create(axiosConfig),
      pickQueue: 0,
      picking: 0,
      readyForCollection: 0,
      loading: false
    }
  },
  mounted () {
    this.$emit('openMenu')
    if (this.storeId) {
      this.loading = true
      this.fetchData().finally(() => (this.loading = false))
    }
  },
  watch: {
    storeId: 'fetchData'
  },
  computed: {
    ...mapState('instore', ['storeId', 'storeName']),
    ...mapState(['currentTime']),
    formattedCurrentTime: function () {
      if (this.currentTime) {
        return this.currentTime.format('HH:mm:ss')
      } else {
        return '...'
      }
    }
  },
  methods: {
    fetchData: async function () {
      const states = [
        statusMapping.CREATED.db,
        statusMapping.PICKING.db,
        statusMapping.PICKED.db,
        statusMapping.READY_FOR_NOTIFICATION.db,
        statusMapping.SMS_SENT.db,
        statusMapping.ARRIVAL_TIME_SET.db
      ]

      const token = await this.$auth.getTokenSilently()
      // TODO: move this out into the store.
      return this.axios.get(`/admin/store/${this.storeId}`, {
        params: {
          states: states
        },
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        }
      })
        .then(response => response.data)
        .then(data => {
          const result = {}
          for (const state in data) {
            result[state] = data[state].length || 0
          }
          return result
        })
        .then(stateCounts => {
          this.pickQueue = stateCounts.created
          this.picking = stateCounts.picking
          this.readyForCollection = stateCounts.arrivalTimeSet + stateCounts.picked + stateCounts.readyForNotification + stateCounts.smsSent
        })
    }
  }
}
</script>

<style scoped>

</style>
